/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/scss/bootstrap';
@import './assets/styles/_amerenStyleGuide';
@import './assets/styles/_animate.scss';

body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
}

.card {
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  box-shadow: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #ffffff;
}
.custom-control-label {
  cursor: pointer;
}

.btn {
  border-radius: 2.5rem;
  font-weight: 700;
}

.snack-success {
  background-color: $brand-success;
  padding: 2em !important;
  color: #fff;
}

.snack-error {
  background-color: $brand-danger;
  padding: 2em !important;
  color: #fff;
}

.pointer {
  cursor: pointer;
}
.title h3 {
  font-size: 45px;
  font-weight: 500;
}
.title p {
  font-size: 23px;
  margin-top: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .login {
    width: 20rem !important;
  }
  .title h3 {
    font-size: 27px;
    margin-top: 50px;
  }
  .title p {
    font-size: 18px;
    margin-top: 15px;
  }
}

@media (min-width: 301px) and (max-width: 767px) {
  .login {
    width: 100% !important;
  }
  .title h3 {
    font-size: 37px;
  }
  .title p {
    font-size: 18px;
    margin-top: 15px;
  }
  .ameren-font-sm {
    font-size: 12px !important;
  }
  .page-header {
    font-size: 21px !important;
  }

  .section-header {
    font-size: 16px !important;
  }
}

@media (min-width: 250px) and (max-width: 300px) {
  .login {
    width: 100% !important;
  }
  .title h3 {
    font-size: 30px;
  }
  .title p {
    font-size: 18px;
    margin-top: 15px;
  }
  .ameren-font-sm {
    font-size: 12px !important;
  }
  .page-header {
    font-size: 21px !important;
  }

  .section-header {
    font-size: 16px !important;
  }
}

.ameren-font-sm {
  font-size: 14px;
}

.page-header {
  color: #037000;
  font-size: 25px;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.section-header {
  font-size: 20px;
  font-weight: 400;
  color: #565656;
  padding-left: 0.5rem;
}

label.required:after {
  content: ' *';
  color: #e02020;
}

.form-control {
  border-radius: 6px;
  border-width: 1.5px;
  border-color: #c6c6c6;
  margin-top: 0.2rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem !important;
  font-size: 1.15rem;
}
.form-select {
  border-radius: 8px;
  border-width: 1.5px;
  border-color: #c6c6c6;
}
.form-group {
  padding: 0.5rem;
  .ng-dirty.ng-invalid + svg {
    width: 10px;
    fill: #d2332f;
    position: absolute;
    left: 64%;
    margin-top: -1.5rem;
  }
  .ng-dirty.ng-invalid {
    border-color: #d2332f;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d2332f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d2332f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.float-clear-both {
  clear: both;
}
a {
  color: #1b6cb5;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.text-field-error {
  color: #d2332f;
  padding-left: 0.2rem;
  padding-top: 0.1rem;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
